/* eslint-disable unicorn/no-null */
import { CountryInfo, FieldContainer } from './address-field-helper';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, ValidatorFn } from '@angular/forms';
import { OnlinePaymentMethodGroup } from '@app/checkout/modules/checkout-shared/reducers/checkout.reducer';
import Debug from 'debug';
import { merge, Observable, Subject } from 'rxjs';
import { debounceTime, map, shareReplay } from 'rxjs/operators';

const debug = Debug('ecom:form-helper');

export const PAYMENT_METHOD_GROUPS = ['bank', 'mobile', 'creditcard', 'credit'] as const;
export const PAYMENT_METHOD_GROUP_TITLES = {
	bank: $localize`:@@PaymentMethodGroupBank:Bank`,
	mobile: $localize`:@@PaymentMethodGroupMobile:Mobile`,
	creditcard: $localize`:@@PaymentMethodGroupCreditCard:Credit card`,
	credit: $localize`:@@PaymentMethodGroupCredit:Credit`,
};

export const defaultInvoiceMessageVariables = [
	'user.firstName',
	'user.lastName',
	'user.email',
	'user.phone',
	'user.companyName',
	'user.vatNumber',
] as const;

export type ValidationMessages = Record<string, Record<string, string>>;
export type FormErrors = Observable<Record<string, string>> & {
	/** Update the error messages without triggering the form updates. */
	refresh: () => void;
};

/** Get an observable with the validation errors for a form. */
export function formValidationErrors$(
	form: UntypedFormGroup,
	validationMessages: ValidationMessages,
	debounce = 500,
): FormErrors {
	const refresh = new Subject<void>();
	const errors$ = merge(form.valueChanges, refresh).pipe(
		debounceTime(debounce),
		map(() => formValidationHelper(form, validationMessages)),
		shareReplay(1),
	) as FormErrors;
	errors$.refresh = () => refresh.next();
	return errors$;
}

/**
 * Get the validation errors for a form.
 * Use this function if you want to pass your own custom observer and run form.valueChanges() manually.
 */
export function formValidationHelper(
	form: UntypedFormGroup,
	validationMessages: ValidationMessages,
): Record<string, string> {
	const hasError = (field: string) => form.get(field)?.dirty && !form.get(field)?.valid;
	const getError = (field: string) => {
		const errors = form.get(field)?.errors || {};
		const errorMessage = Object.entries(errors)
			.map(([key]) => validationMessages[field][key])
			.find((message) => message?.length > 0);
		if (!errorMessage)
			debug(`formValidationHelper: No error message found for field "${field}"`, errors);
		return errorMessage ?? '';
	};
	return Object.fromEntries(
		Object.entries(validationMessages).map(([field]) => [field, hasError(field) ? getError(field) : '']),
	);
}

/** A simple phone number validator. */
export function validatePhoneNumber(phoneNumber: unknown): { phoneValid: boolean } | null {
	if (typeof phoneNumber !== 'string' || !phoneNumber) return null;
	// Allow spaces to make copy pasting easier
	const withoutSpaces = phoneNumber.replaceAll(' ', '');
	// Maximum length of 25 characters is set by Mylly
	if (withoutSpaces.length > 25) return { phoneValid: true };
	// Allow numbers and a plus sign at the beginning
	if (!/^\+?\d{5,25}$/.test(withoutSpaces)) return { phoneValid: true };
	return null;
}

/** A simple ToS acceptance validator. */
export function validateTerms(control: UntypedFormControl): { acceptTerms: boolean } | null {
	return control.value ? null : { acceptTerms: true };
}

/** A helper function to parse a code from string and patch it to the form. */
export function parseCode(code: string, form: UntypedFormGroup): void {
	// Remove everything except numbers when necessary
	if (typeof code !== 'string' || /^\d*$/.test(code)) return;
	code = code.replaceAll(/\D/g, '');
	form.patchValue({ code });
}

export const equalTo = (equalControl: AbstractControl): ValidatorFn => {
	let hasSubscribed = false;

	return (control: AbstractControl): Record<string, boolean> | null => {
		if (!hasSubscribed) {
			hasSubscribed = true;
			equalControl.valueChanges.subscribe(() => {
				control.updateValueAndValidity();
			});
		}
		return equalControl.value === control.value ? null : { equalTo: true };
	};
};

/**
 * Custom pattern validator since Angular pattern validator doesn't handle empty strings
 */
export function customPatternValidator(rx: RegExp): ValidatorFn {
	return (control: AbstractControl): { customPattern: unknown } | null => {
		const value = typeof control.value === 'string' ? control.value : '';
		return rx.test(value) ? null : { customPattern: { value } };
	};
}

/**
 * Validates the shipping country based on the required fields and allowed countries.
 */
export function validateShippingCountry(
	requiredFields: FieldContainer,
	allowedCountries: string[] | CountryInfo[],
): ValidatorFn {
	return (control: AbstractControl) => {
		const allowedCountriesStr: string[] =
			typeof allowedCountries[0] === 'object'
				? (allowedCountries as CountryInfo[]).map((c) => c.name)
				: (allowedCountries as string[]);
		// Allow country input to be empty if the field is not required
		const requiredShippingFields = requiredFields.shipping;
		if (!control.value && !requiredShippingFields.has('country')) {
			return null;
		}

		return allowedCountriesStr.length === 0 || allowedCountriesStr.includes(control.value as string)
			? null
			: { countryShippingDisabled: { value: control.value as string } };
	};
}

/** Resolve a variable from a nested object */
export function resolveTextVariable<T>(
	context: T,
	variable: `${PlainKeys<T>}.${PlainKeys<T[PlainKeys<T>]>}`,
): Primitive {
	const [target, prop] = variable.split('.') as [keyof T, keyof T[keyof T]];
	const value = context?.[target]?.[prop];
	// istanbul ignore if -- tricky to test
	if (Array.isArray(value)) return value.join(', ');
	if (!value || typeof value === 'object') return '';
	return value as Primitive;
}

/** Get sorted online payment methods groups. */
export function getOnlinePaymentMethodGroups(
	paymentMethods: Map<string, api.PaymentMethodProviderDto[]>,
): { title: string; id: OnlinePaymentMethodGroup }[] {
	return PAYMENT_METHOD_GROUPS.filter((group) => paymentMethods.get(group)?.length ?? 0 > 0).map(
		(group) => ({
			title: PAYMENT_METHOD_GROUP_TITLES[group],
			id: group,
		}),
	);
}

/** Returns the invoice code variables including custom user properties. E.g. user.firstname. */
export function getInvoiceCodeVariables(
	customUserSchema?: api.JsonSchemaDto,
): `user.${PlainKeys<api.UserDto>}`[] {
	const customUserProps = customUserSchema?.properties as Record<string, { isHiddenFromUser: boolean }>;
	if (!customUserProps) return [...defaultInvoiceMessageVariables];
	const customInsertables: `user.${PlainKeys<api.UserDto>}`[] = [];
	for (const key of Object.keys(customUserProps)) {
		if (customUserProps[key]?.isHiddenFromUser) continue;
		customInsertables.push(`user.${key as PlainKeys<api.UserDto>}`);
	}

	return [...defaultInvoiceMessageVariables, ...customInsertables];
}
