import { setName } from '../utils/name-helper';
import { filterTruthy, getDebug, tryParseJson } from '../utils/util';
import { CognitoService } from './cognito.service';
import { NewRelic } from './newrelic.service';
import { StoreDataService } from './store-data.service';
import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { getCategories as fetchCategories } from '@app/api/action/Category';
import { myLists } from '@app/api/action/ProductList';
import { allowedShippingMethods, getDeliveryDates } from '@app/api/action/ShippingMethod';
import { ME as CurrentUser, anonymousUser as getAnonymousUser } from '@app/api/action/User';
import { RootReducer, Store } from '@app/app.reducers';
import { LOGIN_URL } from '@app/app.router.urls';
import { resetCategoryState } from '@app/category/reducers/category.reducer';
import {
	ResetCheckoutReason,
	resetCheckoutFlow,
	setShowPricesWithTax,
} from '@app/checkout/modules/checkout-shared/reducers/checkout.reducer';
import { resetProductLists } from '@app/checkout/modules/checkout-shared/reducers/persisted-list.reducer';
import { resetProductState } from '@app/product/reducers/product.reducer';
import { hasLoggedOut, resetLogout } from '@app/user/reducers/auth.reducer';
import { getUser, isLoggedUser } from '@app/user/reducers/user.reducer';
import { environment } from '@src/environments/environment';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged, filter } from 'rxjs/operators';

/** Service for handling authentication. */
@Injectable({ providedIn: 'root' })
export class AuthService {
	readonly isAuthenticatingSso$ = new BehaviorSubject<boolean>(true);
	readonly ssoAuthenticationError$ = new BehaviorSubject<string | undefined>(undefined);
	private readonly debug = getDebug('AuthService');
	private readonly isAnonAllowed =
		this.storeData.storeData.storefront?.allow_anonymous_browsing ?? false;
	private readonly isSharedAuthEnabled = this.storeData.storeData.use_shared_auth;
	private readonly customerSlug = this.storeData.storeData.cas_customer_slug;

	constructor(
		private readonly router: Router,
		private readonly store: Store<RootReducer.State>,
		private readonly cognito: CognitoService,
		private readonly newRelic: NewRelic,
		private readonly storeData: StoreDataService,
		@Inject(DOCUMENT) private readonly document: Document,
	) {}

	/** Initialize the service. */
	init(): void {
		this.cognito
			.getFederatedSession()
			.catch((err: string | Error) => {
				let errMessage: string;
				if (typeof err === 'string') {
					// Cognito client errors are "valid JSON"
					// so try to parse them and get their content
					const cognitoClientError = tryParseJson<Record<'code' | 'error', string>>(err);
					errMessage = cognitoClientError?.code ?? cognitoClientError?.error ?? err;
					this.newRelic.noticeError(new Error(`Federated login failed: ${errMessage}`));
				} else {
					errMessage = err.message;
					this.newRelic.noticeError(err);
				}
				this.ssoAuthenticationError$.next(errMessage || 'unknown');
			})
			.finally(() => this.isAuthenticatingSso$.next(false));

		// When user logs in, perform actions.
		this.store
			.select(getUser)
			.pipe(
				distinctUntilChanged((u1, u2) => u1?.id === u2?.id),
				filter(isLoggedUser),
			)
			.subscribe((user) => this.onLogin(user));

		// When user logs out, perform actions.
		this.store
			.select(hasLoggedOut)
			.pipe(filterTruthy)
			.subscribe(() => this.onLogout());
	}

	/** Perform actions when user logs in. */
	private onLogin(user: CurrentUser) {
		this.debug('logged in', user.id);
		this.newRelic.setUserId(user.id);
		this.store.dispatch(setShowPricesWithTax(user?.groupMetadata?.defaultShowPricesWithTax ?? true));
		// Fetch persisted shopping cart and My Products.
		this.store.dispatch(myLists());
		// Fetch categories that user has access to.
		this.store.dispatch(setName('fetchCategories', fetchCategories()));
	}

	/** Perform actions when user logs out. */
	private onLogout() {
		this.debug('logged out');
		// eslint-disable-next-line unicorn/no-null
		this.newRelic.setUserId(null);
		this.store.dispatch(resetLogout());
		this.store.dispatch(resetCheckoutFlow(ResetCheckoutReason.Logout));
		this.store.dispatch(resetProductLists());

		// Reset categories and products.
		if (this.isAnonAllowed) {
			// Reset shipping methods and delivery dates.
			this.store.dispatch(setName('allowedShippingMethods', allowedShippingMethods()));
			this.store.dispatch(setName('getDeliveryDates', getDeliveryDates('')));
			// Reset categories and products.
			this.store.dispatch(setName('fetchCategories', fetchCategories()));
		} else {
			this.store.dispatch(resetCategoryState());
			this.store.dispatch(resetProductState());
		}

		if (this.isSharedAuthEnabled) {
			this.document.location.href = `${environment.sharedSsoApi}/token/logout/${this.customerSlug}`;
			return;
		}

		if (!this.isAnonAllowed) {
			this.debug('redirecting to login');
			void this.router.navigate([LOGIN_URL]);
			return;
		}

		this.store.dispatch(getAnonymousUser());
		void this.router.navigate(['']);
	}
}
