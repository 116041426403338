import { I18nService } from '../utils/i18n.service';
import { filterTruthy, firstTruthy, getDebug } from '../utils/util';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { getAddresses } from '@app/api/action/AddressBook';
import { fetchContent } from '@app/api/action/Content';
import { getMyOrders } from '@app/api/action/Order';
import { getPrices } from '@app/api/action/Price';
import { getProductsByIdList } from '@app/api/action/Product';
import { allowedShippingMethods, getDeliveryDates } from '@app/api/action/ShippingMethod';
import { RootReducer, Store } from '@app/app.reducers';
import { getCartItems } from '@app/checkout/modules/checkout-shared/reducers/checkout.reducer';
import { getContentByKey } from '@app/core/reducers/content.reducer';
import { setError } from '@app/product/reducers/product.reducer';
import { setName } from '@app/shared/utils/name-helper';
import { getMyOrders as getFetchedMyOrders } from '@app/user/reducers/order.reducer';
import { getIsUserChecked, getUserPermissions } from '@app/user/reducers/user.reducer';
import { pluckSuccessData } from '@granodigital/grano-remote-data';
import { Observable, of } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';

/** Service to resolve data for routes */
@Injectable({ providedIn: 'root' })
export class ResolveStoreService {
	private readonly debug = getDebug('ResolveStoreService');
	private readonly userPermissions$ = this.store.select(getIsUserChecked).pipe(
		firstTruthy,
		switchMap(() => this.store.select(getUserPermissions)),
		filterTruthy,
	);

	constructor(
		private readonly store: Store<RootReducer.State>,
		private readonly i18n: I18nService,
	) {}

	/** Resolve data for a route */
	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<unknown> {
		const urlResolvers: Record<
			string,
			(permissions?: api.UserGroupPermissionDto) => Promise<void> | Observable<unknown>
		> = {
			'/user/profile': async (permissions) => this.fetchUserProfileData(permissions),
			'/user/profile/order/*': async () => this.fetchOrderProducts(state.url),
			'/checkout/v2/shipping-address': async (permissions) => this.resolveAddresses(permissions),
			'/checkout/v2/billing-address': async (permissions) => this.resolveAddresses(permissions),
			'/checkout/cart': async () => this.fetchCartData(),
			'/checkout/v2/billing-method': async () => this.fetchCartData(),
			'/checkout': async (permissions) => this.fetchCheckoutData(permissions),
			'/page/*': () => this.resolveContent(route.params.contentKey as string)(),
			'/faq': this.resolveContent('faq'),
			'/terms': this.resolveContent('terms'),
			'/about': this.resolveContent('about'),
			'/material-instructions': this.resolveContent('material-instructions'),
			'/product/**': async () => this.fetchProduct(route.params.id as string),
		};
		for (const [pattern, resolver] of Object.entries(urlResolvers)) {
			if (this.urlMatch(state.url, pattern)) return this.userPermissions$.pipe(switchMap(resolver));
		}
		this.debug('no match', state.url);
		return of(undefined);
	}

	/** Resolves the checkout address data based on the user's permissions. */
	private resolveAddresses(permissions: api.UserGroupPermissionDto | undefined) {
		this.debug('resolving checkout address data', permissions?.allow_checkout);
		if (!permissions?.allow_checkout) return;
		this.store.dispatch(setName('getAddresses', getAddresses()));
	}

	/**
	 * Fetches the checkout data based on the user's permissions.
	 * If the user is allowed to checkout, it dispatches actions to get addresses,
	 * allowed shipping methods, and delivery dates for the products in the cart.
	 */
	private fetchCheckoutData(permissions: api.UserGroupPermissionDto | undefined) {
		this.debug('resolving checkout data', permissions?.allow_checkout);
		if (!permissions?.allow_checkout) return;
		this.store.dispatch(getAddresses());
		this.store.dispatch(setName('allowedShippingMethods', allowedShippingMethods()));
		this.store
			.select(getCartItems)
			.pipe(map((products) => [...new Set<number>(products.map((item) => item.product.id))]))
			.subscribe((productIds) => {
				this.store.dispatch(setName('getDeliveryDates', getDeliveryDates(productIds.join(','))));
			});
	}

	/** This method resolves cart data by dispatching an action to get prices needed for the cart. */
	private fetchCartData() {
		this.debug('resolving cart data');
		this.store.dispatch(setName('getPrices', getPrices()));
	}

	/**
	 * Fetches the user profile data based on the provided permissions.
	 * If the `allow_checkout` permission is not granted, the method returns early.
	 * Otherwise, it dispatches actions to get the user's orders and addresses.
	 */
	private fetchUserProfileData(permissions: api.UserGroupPermissionDto | undefined) {
		this.debug('resolving user profile data', permissions?.allow_checkout);
		if (!permissions?.allow_checkout) return;
		this.store.dispatch(setName('getMyOrders', getMyOrders()));
		this.store.dispatch(setName('getAddresses', getAddresses()));
	}

	/** Fetches order products based on the order number in the URL. */
	private fetchOrderProducts(url: string) {
		this.store.dispatch(setName('getMyOrders', getMyOrders()));
		this.store
			.select(getFetchedMyOrders)
			.pipe(first((orders) => Array.isArray(orders) && orders.length > 0))
			.subscribe((orders) => {
				const urlMatch = /^\/user\/profile\/order\/(\d+)$/.exec(url);
				const orderNumber = Array.isArray(urlMatch) && urlMatch.length === 2 ? urlMatch[1] : undefined;
				if (!orderNumber) return;
				const inspectedOrder = orders?.find((order) => order.order_number === orderNumber);
				if (!inspectedOrder) return;
				const productIds = inspectedOrder.products.map((product) => product.id);
				this.store.dispatch(setName('getProductsByIdList', getProductsByIdList(productIds.join(','))));
			});
	}

	/** Fetch product by id */
	private fetchProduct(id: string) {
		this.debug('resolving product', id);
		if (!Number.isFinite(Number.parseInt(id))) return this.store.dispatch(setError());
		this.store.dispatch(setName('getProductsByIdList', getProductsByIdList(id)));
	}

	/** Get content by key */
	private resolveContent(key: string) {
		return () => {
			this.debug('resolving content', key);
			this.dispatchFetchContent(key);
			return this.store.select(getContentByKey(key)).pipe(pluckSuccessData);
		};
	}

	/** Dispatch fetch content action */
	private dispatchFetchContent(key: string) {
		this.store.dispatch(setName('fetchContent', fetchContent(this.i18n.locale, key)));
	}

	/** Check if a url matches a rule with wildcards. */
	private urlMatch(url: string, rule: string): boolean {
		const doubleStarPlaceholder = '__DOUBLE_STAR__';
		const expression =
			'^' +
			rule
				.replaceAll('**', doubleStarPlaceholder) // Temporarily replace ** with a placeholder
				.replaceAll('*', '([^/]*?)') // Replace * with the correct pattern
				.replaceAll(doubleStarPlaceholder, '(.*?)') + // Replace the placeholder with the final pattern
			'(\\?.*?)?$'; // Allow query params at the end.
		return new RegExp(expression).test(url);
	}
}
