<div class="address-summary-content">
	<div
		class="address-container"
		fxLayout="row"
		fxLayout.xs="column"
		fxLayoutGap="0px"
		fxLayoutGap.xs="20px"
	>
		@if (userInfo$ | async; as userInfo) {
			<ul class="address-column user-info" fxLayout="column" fxFlex="33" fxFlex.xs="100">
				<li class="header" i18n="@@OrdererHeader">Orderer</li>
				<li i18n-title="@@OrdererFullName" title="Full Name"
					>{{ userInfo.first_name }} {{ userInfo.last_name }}</li
				>
				<li i18n-title="@@OrdererEmail" title="Email">{{ userInfo.email }}</li>
				<li i18n-title="@@OrdererPhone" title="Phone">{{ userInfo.phone }}</li>
			</ul>
		}

		@if (shippingAddress$ | async; as shippingAddress) {
			<ul class="address-column shipping-address" fxLayout="column" fxFlex="33" fxFlex.xs="100">
				<li class="header" i18n="@@AddressShippingAddressHeader">Shipping Address</li>
				<li i18n-title="@@AddressCompanyLabel" title="Company">{{ shippingAddress.company }}</li>
				<li i18n-title="@@AddressFullNameLabel" title="Full Name"
					>{{ shippingAddress.first_name }} {{ shippingAddress.last_name }}</li
				>
				<li i18n-title="@@AddressPhoneLabel" title="Phone Number">{{ shippingAddress.phone }}</li>
				@if (shippingAddress.email) {
					<li i18n-title="@@AddressEmailLabel" title="Email">{{ shippingAddress.email }}</li>
				}
				<li i18n-title="@@AddressStreetNameLabel" title="Street Name">{{
					shippingAddress.street_name
				}}</li>
				<li i18n-title="@@AddressSecondAddressLineLabel" title="Second Address Line">{{
					shippingAddress.second_address_line
				}}</li>
				<li i18n-title="@@AddressZipCodeLabel" title="Zip Code"
					>{{ shippingAddress.zip_code }} {{ shippingAddress.city }}</li
				>
				<li i18n-title="@@AddressCountryLabel" title="Country">{{ shippingAddress.country }}</li>
			</ul>
		}

		@if (billingAddress$ | async; as billingAddress) {
			<ul class="address-column billing-address" fxLayout="column" fxFlex="33" fxFlex.xs="100">
				<li class="header" i18n="@@AddressBillingAddressHeader">Billing Address</li>
				@if (isBillingAddressCompanyValid$ | async) {
					<li i18n-title="@@AddressCompanyLabel" title="Company">{{ billingAddress.company }}</li>
				}
				@if (isBillingAddressFullNameValid$ | async; as boolean) {
					<li i18n-title="@@AddressFullNameLabel" title="Full Name"
						>{{ billingAddress.first_name }} {{ billingAddress.last_name }}</li
					>
				}
				<li i18n-title="@@AddressStreetNameLabel" title="Street Name">{{
					billingAddress.street_name
				}}</li>
				<li i18n-title="@@AddressZipCodeLabel" title="Zip Code"
					>{{ billingAddress.zip_code }} {{ billingAddress.city }}</li
				>
				<li i18n-title="@@AddressCountryLabel" title="Country">{{ billingAddress.country }}</li>
				@if (isBillingAddressEmailValid$ | async) {
					<li i18n-title="@@AddressEmailLabel" title="Email">{{ billingAddress.email }}</li>
				}
				@if (isBillingAddressEdiNumberValid$ | async) {
					<li i18n-title="@@AddressEdiNumberLabel" title="EDI number"
						>{{ billingAddress.edi_number }} (<b i18n="@@AddressEdiNumberLabel">EDI number</b>)
					</li>
				}
				@if (isBillingAddressOperatorValid$ | async) {
					<li i18n-title="@@AddressOperatorLabel" title="Operator"
						>{{ billingAddress.operator }} (<b i18n="@@AddressOperatorLabel">Operator</b>)</li
					>
				}

				@if (isBillingAddressOperatorIdValid$ | async) {
					<li i18n-title="@@AddressOperatorIdLabel" title="Operator ID"
						>{{ billingAddress.operator_id }} (<b i18n="@@AddressOperatorIdLabel">Operator ID</b>)</li
					>
				}
				<li i18n-title="@@AddressVatNumberLabel" title="VAT number">{{ billingAddress.vat_number }}</li>
			</ul>
		}
	</div>
</div>
