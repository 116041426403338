import { VAT_NUMBER_REGEXP } from './util';
import { UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { AddressFormModel } from '@app/user/reducers/address-book.reducer';

// TODO: Move everything to AddressStepService once legacy code is removed.

export enum AddressType {
	Billing = 'billing',
	Shipping = 'shipping',
}

export interface CountryInfo {
	code: string;
	name: string;
}

export interface AddressFormOptions {
	visibleFields: Set<string>;
	requiredFields: Set<string>;
	validationMessages: typeof addressValidationMessages;
	validationPatterns: ValidationPatterns;
	// TODO: Convert to a map of country codes to country names
	allowedCountries: CountryInfo[];
}

const CUSTOM_MAX_LENGTH_VALIDATORS: Record<string, number | undefined> = {
	street_name: 50,
	second_address_line: 150,
};

export const CUSTOMIZABLE_FIELD_KEYS = [
	'first_name',
	'last_name',
	'company',
	'street_name',
	'second_address_line',
	'zip_code',
	'city',
	'country',
	'email',
	'phone',
	'edi_number',
	'operator_id',
	'operator',
	'vat_number',
	'address_type',
] as const;

export const DEFAULT_PATTERN_VALIDATORS = {
	zip_code: Validators.pattern(/^\d{5}$/),
	email: Validators.pattern(/^\S+@\S+$/),
	vat_number: Validators.pattern(VAT_NUMBER_REGEXP),
};

export interface ValidationPatterns {
	first_name?: ValidatorFn;
	last_name?: ValidatorFn;
	street_name?: ValidatorFn;
	second_address_line?: ValidatorFn;
	zip_code?: ValidatorFn;
	city?: ValidatorFn;
	email?: ValidatorFn;
	phone?: ValidatorFn;
	company?: ValidatorFn;
	country?: ValidatorFn;
	edi_number?: ValidatorFn;
	operator_id?: ValidatorFn;
	operator?: ValidatorFn;
	vat_number?: ValidatorFn;
	[key: string]: ValidatorFn | undefined;
}

export interface FieldContainer {
	billing: Set<string>;
	shipping: Set<string>;
}

export interface ApplicableFields {
	visibleFields: FieldContainer;
	requiredFields: FieldContainer;
}

export type AddressFormSchemas = Partial<
	Record<
		AddressType,
		{ properties?: Record<string, { isVisible: boolean; pattern?: string }>; required?: string[] }
	>
>;

/**
 * Retrieves the applicable fields from the provided schemas.
 * @param schemas The schemas containing the field properties
 * @returns An object containing the visible and required fields
 */
export function getApplicableFields(schemas?: AddressFormSchemas): ApplicableFields {
	const visibleFields = { billing: new Set<string>(), shipping: new Set<string>() };
	const requiredFields = { billing: new Set<string>(), shipping: new Set<string>() };
	if (!schemas) schemas = {};
	for (const type of ['billing', 'shipping'] as const) {
		for (const [key, options] of Object.entries(schemas[type]?.properties ?? [])) {
			if (options.isVisible !== true) continue;
			visibleFields[type].add(key);
		}
		for (const key of schemas?.[type]?.required ?? []) requiredFields[type].add(key);
	}
	return { visibleFields, requiredFields };
}

/**
 * Retrieves the validation patterns for address form fields based on the provided schemas.
 * @param schemas The address form schemas containing the validation patterns.
 * @returns An object containing the validation patterns for each address form field.
 */
export function getValidationPatterns(schemas?: AddressFormSchemas): ValidationPatterns {
	const validationPatterns: ValidationPatterns = {};
	if (!schemas) schemas = {};
	for (const type of ['billing', 'shipping'] as const) {
		for (const [key, options] of Object.entries(schemas?.[type]?.properties ?? [])) {
			if (!options.pattern || validationPatterns[key]) continue;
			validationPatterns[key] = Validators.pattern(new RegExp(options.pattern));
		}
	}
	// Set default validators
	for (const key of Object.keys(DEFAULT_PATTERN_VALIDATORS)) {
		if (!validationPatterns[key]) {
			validationPatterns[key] =
				DEFAULT_PATTERN_VALIDATORS[key as keyof typeof DEFAULT_PATTERN_VALIDATORS];
		}
	}
	return validationPatterns;
}

/**
 * Updates the validators for the form controls based on the selected address types.
 * @param form The form group to update the validators for.
 * @param options The address form options containing the visible and required fields and validation patterns.
 */
export function updateValidators(
	form: UntypedFormGroup,
	options: Pick<AddressFormOptions, 'visibleFields' | 'requiredFields' | 'validationPatterns'>,
): void {
	const { visibleFields, requiredFields, validationPatterns } = options;
	// Clear and set validators based on selected address types
	for (const key of CUSTOMIZABLE_FIELD_KEYS) {
		// Skip non-visible fields
		if (!visibleFields.has(key)) continue;
		form.controls[key].clearValidators();
		const validators: ValidatorFn[] = [];
		// Address rows are special because of Mylly
		validators.push(Validators.maxLength(CUSTOM_MAX_LENGTH_VALIDATORS[key] || 255));
		// Add pattern validator when applicable
		// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
		if (validationPatterns[key]) validators.push(validationPatterns[key]!);
		// Add required validator when applicable
		if (requiredFields.has(key)) validators.push(Validators.required);
		// Set new validators
		form.controls[key].setValidators(validators);
	}
}

export const addressValidationMessages = {
	first_name: {
		required: $localize`:@@ContactInfoFirstNameRequired:First name is required`,
		maxlength: $localize`:@@ContactInfoFirstNameMaxLength:First name cannot be over 255 characters long`,
		pattern: $localize`:@@ContactInfoFirstNameFormat:First name has to be in valid format`,
	},
	last_name: {
		required: $localize`:@@ContactInfoLastNameRequired:Last name is required`,
		maxlength: $localize`:@@ContactInfoLastNameMaxLength:Last name cannot be over 255 characters long`,
		pattern: $localize`:@@ContactInfoLastNameFormat:Last name has to be in valid format`,
	},
	street_name: {
		required: $localize`:@@ContactInfoStreetNameRequired:Street name is required`,
		maxlength: $localize`:@@ContactInfoStreetNameMaxLength:Street name cannot be over 50 characters long`,
		pattern: $localize`:@@ContactInfoStreetNameFormat:Street name has to be in valid format`,
	},
	second_address_line: {
		required: $localize`:@@ContactInfoSecondAddressLineRequired:Second address line is required`,
		maxlength: $localize`:@@ContactInfoSecondAddressLineMaxLength:Second address line cannot be over 50 characters long`,
		pattern: $localize`:@@ContactInfoSecondAddressLineFormat:Second address line has to be in valid format`,
	},
	zip_code: {
		required: $localize`:@@ContactInfoZipCodeRequired:Zip code is required`,
		maxlength: $localize`:@@ContactInfoZipCodeMaxLength:Zip code has to be 5 characters`,
		pattern: $localize`:@@ContactInfoZipCodeFormat:Zip code has to be in correct format`,
	},
	city: {
		required: $localize`:@@ContactInfoCityRequired:City is required`,
		maxlength: $localize`:@@ContactInfoCityMaxLength:City cannot be over 255 characters long`,
		pattern: $localize`:@@ContactInfoCityFormat:City has to be in valid format`,
	},
	country: {
		required: $localize`:@@ContactInfoCountryRequired:Country is required`,
		maxlength: $localize`:@@ContactInfoCountryMaxLength:Country cannot be over 255 characters long`,
		pattern: $localize`:@@ContactInfoCountryFormat:Country has to be in valid format`,
	},
	email: {
		required: $localize`:@@ContactInfoEmailRequired:Email is required`,
		maxlength: $localize`:@@ContactInfoEmailMaxLength:Email cannot be over 255 characters long`,
		pattern: $localize`:@@ContactInfoEmailFormat:Email has to be in right format`,
	},
	phone: {
		required: $localize`:@@ContactInfoPhoneRequired:Phone number is required`,
		maxlength: $localize`:@@ContactInfoPhoneMaxLength:Phone number cannot be over 25 characters long`,
		pattern: $localize`:@@ContactInfoPhoneFormat:Invalid phone number`,
	},
	company: {
		required: $localize`:@@ContactInfoCompanyRequired:Company name is required`,
		maxlength: $localize`:@@ContactInfoCompanyMaxLength:Company name cannot be over 255 characters long`,
		pattern: $localize`:@@ContactInfoCompanyFormat:Company name has to be in valid format`,
	},
	edi_number: {
		required: $localize`:@@ContactInfoEdiNumberRequired:EDI number is required`,
		maxlength: $localize`:@@ContactInfoEdiNumberMaxLength:EDI number cannot be over 255 characters long`,
		pattern: $localize`:@@ContactInfoEdiNumberFormat:EDI number has to be in valid format`,
	},
	operator_id: {
		required: $localize`:@@ContactInfoOperatorIdRequired:Operator ID is required`,
		maxlength: $localize`:@@ContactInfoOperatorIdMaxLength:Operator ID cannot be over 255 characters long`,
		pattern: $localize`:@@ContactInfoOperatorIdFormat:Operator ID has to be in valid format`,
	},
	operator: {
		required: $localize`:@@ContactInfoOperatorRequired:Operator is required`,
		maxlength: $localize`:@@ContactInfoOperatorMaxLength:Operator cannot be over 255 characters long`,
		pattern: $localize`:@@ContactInfoOperatorFormat:Operator has to be in valid format`,
	},
	vat_number: {
		required: $localize`:@@ContactInfoVatNumberRequired:VAT number is required`,
		maxlength: $localize`:@@ContactInfoVatNumberMaxLength:VAT number cannot be over 20 characters long`,
		pattern: $localize`:@@ContactInfoVatNumberFormat:VAT number has to be in valid format`,
	},
};

/**
 * Converts an address from the API format to the address form model.
 */
export function convertAddress(model: api.AddressBook): AddressFormModel {
	const { address_id, ...modelAddress } = model.address as api.AddressDataDto;

	return {
		id: model.id,
		address_type: {
			address_type: model.address_type as AddressType,
			is_common: !!model.is_common,
		},
		set_as_default: model.is_user_default,
		...modelAddress,
	};
}
