import { AfterViewChecked, Directive, ElementRef } from '@angular/core';

@Directive({
	selector: '[gAutofocus]',
})
export class AutofocusDirective implements AfterViewChecked {
	private readonly element: HTMLElement;
	private hasFocused: boolean;

	constructor(element: ElementRef) {
		this.element = element.nativeElement;
		this.hasFocused = false;
	}

	ngAfterViewChecked() {
		if (!this.hasFocused) {
			// HACK: Prevent modal from scrolling to bottom of page
			setTimeout(() => this.element.focus(), 100);
			this.hasFocused = true;
		}
	}
}
